<template>
  <v-container class="grid-list-lg">
    <v-row dense justify="space-around">
      <v-col cols="12" class="mb-4">
        <v-card class="fill-height-90 mt-2" min-height="370px" tile flat>
          <v-list-item>
            <v-list-item-avatar aspect-ratio="1" style="border-radius: 0" min-width="46px">
              <span class="grey--text text--darken-2 headline"><strong style="letter-spacing: 1px">P</strong><strong
                  class="green--text">24</strong></span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">ПП "Укрспецінфо"</v-list-item-title>
              <v-list-item-subtitle>Сервіс "Єдина квитанція"</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Сервіс "Єдина квитанція", де Ви зможете швидко оплатити послуги комунальної сфери,
              передати покази та звірити стан розрахунків
            </p>
            <h4 class="pb-1">Контактна інформація</h4>
            <p>При винекнені проблем із оплатою або роботою сервісу - звертайтесь до нащого call центру, або пишіть нам
              на електронну пошту </p>
            <v-row no-gutters>
              <v-col cols="12" md="6" sm="12" xs="12" class="grey--text">
                <p>
                  <strong class="strong-color">Режим роботи call центру</strong><br>
                  пн-пт 8:00-18:00<br>
                  сб-нд 8:00-12:00
                </p>
                <p class="pb-1">
                  <strong class="strong-color">Електронна пошта</strong><br>
                  <a href="mailto: support@pay24.net.ua" class="grey--text">support@pay24.net.ua</a>
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="12" xs="12">
                <p class="pb-1 mb-0">47000, Україна, м. Тернопіль, вул. Медова, 2</p>
                <ul>
                  <li><a href="tel:+380962636049" class="grey--text">096 263 60 49</a></li>
                </ul>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {LocalityAPI} from '@/api/locality'

export default {
  name: "ShortAbout",
  data() {
    return {
      region: 2000000,
      regions: [],
      contactInfo: [
        {
          region: 2000000,
          contact: [
            {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline', color: 'info'},
            {value: '0969999999', icon: 'mdi-deskphone', color: 'error'},
            {value: 'ternopil-support', icon: 'mdi-skype-business', color: 'primary'},
            {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius', color: 'success'},
          ]
        },
        {
          region: 2300000,
          contact: [
            {value: 'khmelnitskiy-support@gmail.com', icon: ' mdi-email-outline'},
            {value: '0969999999', icon: 'mdi-deskphone'},
            {value: 'ternopil-support', icon: 'mdi-skype-business'},
            {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
          ]
        },
        {
          region: 2600000,
          contact: [
            {value: 'chernivtsy-support@gmail.com', icon: ' mdi-email-outline'},
            {value: '0969999999', icon: 'mdi-deskphone'},
            {value: 'ternopil-support', icon: 'mdi-skype-business'},
            {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
          ]
        },
        {
          region: 900000,
          contact: [
            {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline'},
            {value: '0969999999', icon: 'mdi-deskphone'},
            {value: 'ternopil-support', icon: 'mdi-skype-business'},
            {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
          ]
        },
        {
          region: 1000000,
          contact: [
            {value: 'ternopil-support@gmail.com', icon: ' mdi-email-outline'},
            {value: '0969999999', icon: 'mdi-deskphone'},
            {value: 'ternopil-support', icon: 'mdi-skype'},
            {value: 'м. Тернопіль, вул. Медова 2, офіс 8', icon: 'mdi-map-marker-radius'},
          ]
        }
      ],
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    async getRegions() {
      try {
        const {data} = await LocalityAPI.regions();
        this.regions = data;
      } catch (e) {
        this.$snackbar("Помилка одержання областей", 'error')
      }
    },
  },
  computed: {
    contactData() {
      if (this.region) {
        let contactObject = this.contactInfo.filter(item => item.region === this.region);
        if (contactObject.length > 0) {
          return contactObject[0].contact;
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.getRegions();
  }
}
</script>

<style scoped>

</style>