<template>
  <v-container fluid class="container-max-width fill-height">
    <v-bottom-sheet v-model="show_about" inset>
      <v-sheet
          class="text-center"
          height="70%"
      >
        <ShortAbout />
      </v-sheet>
    </v-bottom-sheet>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" md="12" class="white">
          <v-card
            :height="`${$vuetify.breakpoint.mdAndUp ? '600px' : '500px'}`">
            <v-row no-gutters  class="fill-height">
              <v-col :cols="cols(0, 4, 12, 12)" md="4" sm="12" style="border: .5px solid #e8e8e8;" v-if="$vuetify.breakpoint.mdAndUp">
                <v-row no-gutters align="center" justify="center" class="fill-height background pl-4" :style="{ background: 'url(' + publicPath + 'images/background.jpg' + ')' }">
                  <div class="my-2 px-4 py-10 mr-3 white grey--text text--darken-1 offset-title elevation-8">
                    <span class="font-weight-bold display-2 pa-1 pay24-title"
                          style="cursor: pointer;"
                          @click.stop="show_about = true">
                      Pay24

                    </span>
                    <div class="divider mt-2 ml-1 success"></div>
                    <div class="ml-2 pa-2">
                      <span class="font-weight-regular subtitle-1">
                        Сервіс для зручної оплати комунальних послуг,
                        в якому усі послуги об’єдані в одному місці. <br>
                      </span>
                      <span class="font-weight-regular subtitle-1 mt-12">
                        Якщо у Вас виникли питання зв’яжіться
                        <a href=""
                           class="success--text"
                           @click.stop.prevent="show_about = true">
                          <b> з нами </b>
                        </a>
                      </span>
<!--                    <span class="font-weight-regular subtitle-1">-->
<!--                      Для використання усіх можливостей сервісу <router-link :to="{name: 'registration'}"><b> зареєструйтеся</b></router-link> або <router-link :to="{name: 'login'}"><b>увійдіть</b></router-link>-->
<!--                    </span>-->
                    </div>
                  </div>
                </v-row>
              </v-col>
              <v-col :cols="cols(0, 8, 12, 12)" md="8" sm="12">
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="success darken-2"
                    class="grey lighten-5"
                    grow>
                    <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
                    <v-tab v-text="$vuetify.breakpoint.xsOnly ? 'Пошук по особовому' : ' Пошук по особовому рахунку'"></v-tab>
                    <!--ПОШУК ПО АДРЕСІ-->
                    <v-tab>Пошук по адресі</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <!--ПОШУК ПО ОСОБОВОМУ РАХУНКУ-->
                    <v-tab-item class="px-2">
                      <SearchByPersonIdInternal></SearchByPersonIdInternal>
                    </v-tab-item>
                    <!--ПОШУК ПО АДРЕСІ-->
                    <v-tab-item class="px-2">
                      <SearchByAddress></SearchByAddress>
                    </v-tab-item>
                  </v-tabs-items>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import SearchByAddress from '@/components/searchAccount/SearchByAddress'
  import SearchByPersonIdInternal from '@/components/searchAccount/SearchByPersonIdInternal'
  import ShortAbout from '@/components/ShortAbout'
  import {cols} from "@/utils";

  export default {
    name: "SearchView",
    components: {
      SearchByPersonIdInternal,
      SearchByAddress,
      ShortAbout
    },
    data () {
      return {
        tab: null,
        publicPath: process.env.BASE_URL,
        show_about: false
      }
    },
    methods: {
      cols
    }
  }
</script>

<style scoped lang="scss">
  .pay24-title {
    cursor: pointer;
    &:hover {
      color: #0f8f2f !important;
    }
  }
  .container-max-width {
    max-width: 1560px;
  }
  .offset-title {
    position: relative;
    left: -38px;
    border-radius: 4px;
  }
  .divider {
    width: 100%;
    height: 2px;
    background-color: currentColor;
  }
  .background {
    filter: saturate(0.5);
  }
  a {
    text-decoration: none;
  }
</style>
